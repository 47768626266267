.container{
    text-align:center;
    width:100%;
    height: 650px;
    margin: 0 auto;
    color:#1e2d3b;
    /* background-color:#e1e0e0; */
    opacity:0.8;
    padding:10px;
    font-family: Poppins,sans-serif;
}
.header{
    width:80%;
    margin: 10% 10%;
}
.active li {
    display: none;
}
.header1{
    color:#1e2d3b;
    font-weight: bold;
    font-size:2.5rem;
    line-height:1.3em;
    margin-bottom: 1.5em;
}

.provider{
    font-size: 1.35rem;
    font-weight: 600;
    margin-bottom: 1.8rem;
    color:#1e2d3b ;
}
@media only screen and (max-width:466px){
    .header{
        margin: 5% 10%;
    }
    .header1{
        font-size: 2rem;
    }
    .provider{
        font-size: 1.1rem;
    }
}