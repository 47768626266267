ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    text-align: start;
    padding: 1%;
}

li {
    margin: 1%;
    cursor: pointer;
}


.errList {
    list-style-type: initial;
    margin: 0 2%;
}

.errList li {
    color: red;
    margin: 0.2%;
    cursor: auto;
}


.ProviderCodesMatched {
    border: 0.5px solid lightgray;
}

.ProviderCodesMatched li {
    border-bottom: 0.5px solid lightgray;
    padding: 0.5rem;
    margin: 0.3%;
}

.ProviderCodesMatched li:hover, .ProviderCodesMatched li:active {
    background-color: #3ab449;
    color: white;
}

.del {
    color: red;
    text-decoration: underline;
    cursor: pointer;
}

th {
    padding: 5px 17px;
}

thead {
    background: #3ab449;
    color: white;
}

/* Sanad Nav Bar  */

.SanadNavBar {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f3f3f3;
}

.SanadNavBar li {
    float: left;
    margin: 0px !important;
}

.SanadNavBar li:hover {
    background-color: #3ab449;
}

.SanadNavBar li a {
    display: block;
    color: #666;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.SanadNavBar li:hover a {
    color: white;
}