* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.table thead th,
.table td {
  vertical-align: middle !important;
}

.requestContainer {
  display: flex;
  flex-direction: column;
}
.media {
  margin: 10px;
  text-align: center;
}
.sol_prov_stl {
  background: linear-gradient(0deg, #a3bfe0, #e8ebf8);
  margin: 0px 10px;
  text-align: center;
}
.sol_payer_stl {
  background: linear-gradient(0deg, #a3bce0, #eaebf8);
  margin: 0px 10px;
  text-align: center;
}
.media div {
  border: 0px solid;
}
.media ul {
  text-align: center;
}
.popup {
  position: fixed;
  /* Stay in place */
  z-index: 1010;
  /* Sit on top */
  padding-top: 150px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.MainPage {
  display: flex;
  flex-direction: column;
}

/* .link:hover,
.subHeader .link:hover {
  background-color: #3ab449;
  color: white !important;
}
.link{
  border-radius: 5px;
} */

.partners {
  margin-top: 50px;
  margin-bottom: 30px;
}

.partners h2 {
  color: #3ab449;
  text-align: center;
  padding-bottom: 2%;
}

.partnersLogs {
  display: flex;
  justify-content: space-around;
  margin: 8% 0 4% 0;
}
.par_img {
  width: 320px;
  height: 180px;
}
.team {
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.foo {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}
.foo td {
  padding: 20px;
  border: 0px;
  font-size: 20px;
}
.foo form {
  width: 100%;
}
.team h2 {
  display: flex;
  align-self: center;
  color: #3ab449;
  padding-bottom: 2%;
}
#errmsgminus {
  width: 50%;
  margin-right: 100px auto;
  color: "red";
}
#errmsgrequired {
  width: 50%;
  margin-right: 100px auto;
}
.formtitle {
  color: rgb(58, 180, 73);
}
.errmsg {
  width: 50%;
  margin-right: 100px auto;
}
#t2 {
  width: 70%;
  margin: 10px auto;
}
#t3 {
  width: 70%;
  margin: 10px auto;
}
.deleteicd {
  color: red;
  text-decoration: underline;
}

span.MuiIconButton-label,
span.MuiSwitch-thumb,
span.MuiTouchRipple-root,
span.MuiSwitch-track {
  margin-left: 0;
}

.user-managment-container {
  display: flex;
  flex-direction: column;
  margin: 2%;
}

.tablesContainer {
  display: inline-block;
}

.ActionDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.myheader {
  padding-top: 24px !important;
  padding-bottom: 35px !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.headerItems a {
  color: black !important;
  padding-left: 30px !important;
  font-size: 16px;
  color: #2d2d2d;
  font-weight: 500;
}

.caption p {
  font-size: 30px;
  font-weight: 500;
  color: #5fbfe8;
}

.caption {
  position: relative !important;
  top: 70px !important;
  text-align: center !important;
  right: 0 !important;
  padding-top: 0 !important;
  left: 0 !important;
}

.caption2 {
  position: relative !important;
  top: 70px !important;
  text-align: center !important;
  right: 0 !important;
  padding-top: 0 !important;
  left: 0 !important;
  margin-top: 53px !important;
}

.caption2 p {
  font-size: 30px;
  font-weight: 500;
  color: white;
}

.kayanCaption {
  font-size: larger;
}

.Justify {
  text-align: justify;
}

.grad {
  background-image: linear-gradient(to top, #a4c5ee, #e8ebf8);
}

.grad2 {
  height: 290px;
  background-color: #3ab449;
  background-image: linear-gradient(to left, #3ab449, #28aae1);
}

.grad h1 {
  text-align: center;
  padding-top: 40px;
}

.center {
  width: 60%;
  margin: auto;
}

.grad .center {
  text-align: center;
  font-size: 22px;
}

.whyKayan .whyKayanPar {
  color: rgb(255, 255, 255);
  position: relative;
  top: 100px;
  left: 66px;
  font-size: 31px;
}

.whyKayan ul {
  list-style: none !important;
}

svg {
  margin-left: 10px;
}

.ourSol {
  color: #3ab449;
  text-align: center;
  margin: 3% 0;
}

.whyKayan li {
  display: -webkit-box;
  margin-bottom: 20px;
  text-align: justify;
  font-size: initial;
}

.light {
  font-size: 30px;
  border-width: 1px;
  background: #28aae1;
  color: #ffffff;
  border-color: #333333;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  margin-right: 20px;
}

.circle {
  width: 290px;
  height: 290px;
  background-color: #64a9c6;
  border-radius: 50%;
  position: relative;
  top: 67px;
}
#link-dl-xls:hover {
  color: #6996ff;
  cursor: pointer;
  text-decoration: underline;
}

.SolDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.SubSolDiv {
  display: flex;
  flex-direction: column;
}

.solItem {
  width: 30vw;
  background-color: #28aae108;
  border-radius: 10px;
  margin: 2%;
}

.ClosedSolItemHeight {
  height: 50vh;
}

.OpenedSolItemHeight {
  height: 100vh;
}

.solItem h3 {
  text-align: center;
  padding-top: 10px;
  color: #3ab449;
}

.solItem ul {
  text-align: left;
}

.solItem p {
  text-align: justify;
  padding: 5vh;
}

.more {
  background: none;
  border: none;
  color: #4cbb59;
}

.feautersList {
  text-align: left;
}

.eshLog {
  max-width: 450px;
}

pre {
  text-align: left;
}

.Form {
  width: 50%;
  margin: auto;
}

label {
  color: #0000007d;
  float: left;
}

.header,
.subHeader {
  overflow: hidden;
  background-color: #ffffff;
  padding: 20px 10px;
}
.subHeader2 {
  height: 65px;
  background-color: #ffffff;
  padding: 20px 10px;
}
.cards_size {
  max-width: 400px;
  height: 300px;
  align-self: center;
}
.card_css {
  min-height: 100%;
  min-width: 300px;
  background: linear-gradient(to top, #a3bfe0, #e8ebf8);
}
.col_css {
  padding-left: 1px;
  padding-right: 1px;
}
.button_style {
  margin: 5px;
  width: 100%;
}
.header a,
.subHeader a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

.header .link:hover,
.subHeader .link:hover {
  background-color: #3ab449;
  color: white !important;
}

a:hover {
  text-decoration: none !important;
}

.header a.active {
  background-color: dodgerblue;
  color: white;
}

.header-right {
  float: right;
  margin-top: 10px;
}

.BayanLinks {
  width: 50%;
  margin: 40px auto;
  padding: 20px;
}

.BayanLinks a {
  display: block;
  color: white;
  background-color: #3ab449;
  width: 50%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 30px;
}

.BayanLinks a:hover {
  color: black;
}

.KayanHealthcareOffices {
  display: flex;
  flex-direction: row;
}

.OfficeStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.OtherPadding {
  padding-top: 2.5vh;
}

.locationIcon {
  display: flex;
}

.ceo {
  display: flex;
  flex-direction: row;
  padding: 0px 5%;
  align-self: center;
}

.ceoPhoto {
  width: 15vw;
  height: auto;
  align-self: center;
  border-radius: 50% !important;
  margin-bottom: 2vh;
}

.Footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: rgb(58, 180, 73);
  background-color: rgba(40, 170, 225, 0.12);
  padding: 1%;
}

/* Price Management Styles*/

.PriceManagementHomePage {
  margin-top: 10%;
  display: inline-grid;
}

.new-modal-form {
  width: 100%;
  height: 100%;
  box-shadow: none;
  margin-top: 0;
  padding: 0;
  text-align: center;
}
.new-modal-form button {
  margin-left: auto;
}

/* Mobile Design */
@media screen and (max-width: 1200px) {
  .header a,
  .subHeader a,
  .subHeader button {
    float: none;
    display: block;
    text-align: left;
  }

  .subHeader2 a {
    float: left;
    display: block;
    text-align: left;
    width: 100%;
  }
  .subHeader2 {
    height: 110px;
  }

  .header-right {
    float: left;
  }
  .caption2 {
    display: none;
    height: 50px;
  }
  .caption {
    display: none;
    height: 50px;
  }
  .kayanCaption {
    margin-top: 0px !important;
    float: left !important;
    max-width: 100% !important;
  }
  .clearOne {
    height: 320px !important;
  }
  .circle {
    margin-left: -11%;
  }
  .whyKayanCircle {
    margin-top: -89px;
    margin-left: 46px;
    margin-bottom: 100px;
  }

  .SolDiv {
    flex-direction: column;
  }

  .solItem {
    width: auto;
  }

  .ClosedSolItemHeight,
  .OpenedSolItemHeight {
    height: auto;
  }

  .solItem ul {
    text-align: center;
  }

  .partnersLogs {
    flex-direction: column;
  }

  .partnerLog {
    margin: 4% 0;
  }

  .eshLog {
    max-width: 300px;
  }
  .clearBody {
    height: 0px !important;
  }
  .copy {
    display: contents !important;
    text-align: left;
  }
  .info {
    display: contents !important;
    text-align: left;
  }

  .BtnsContainer {
    width: 100%;
  }

  .BayanLinks {
    width: 100%;
  }

  .BayanLinks a {
    width: 100%;
  }

  .center {
    width: auto;
  }

  .ceo {
    flex-direction: column;
  }

  .ceoPhoto {
    width: 40vw;
  }

  .Footer {
    flex-direction: column;
    padding: 2rem;
  }

  .KayanHealthcareOffices {
    flex-direction: column;
  }

  .OfficeStyle {
    margin-bottom: 1vh;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 420px) {
  .clearOne {
    height: 425px !important;
  }
}

@media screen and (max-width: 330px) {
  .clearOne {
    height: 500px !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
