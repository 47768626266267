:root {
  /* Colors: */
  --table-color: #ffffff;

  --menu-font-077756: #077756;

  --back-color-ebebeb: white;
}

body {
  background: var(--back-color-ebebeb) !important;
}

/* barra geral */
.MuiDrawer-paper {
  background-color: var(--menu-font-077756) !important;
  color: var(--back-color-ebebeb) !important;
  box-shadow: 5px 5px 10px 0px rgba(50, 50, 50, 0.75) !important;
  border: none !important;
}
.tabActive {
  background-color: #3ab449;
}
.tab1 {
  margin-left: 0.7rem;
  margin-top: 0.2rem;
}
.tabActive1 {
  margin-left: 0.7rem;
  margin-top: 0.2rem;
  background-color: #3ab449;
}
.MuiToolbar-root {
  background-color: var(--back-color-ebebeb);
}

.MuiListItemIcon-root svg {
  color: var(--back-color-ebebeb);
}
.MuiList-root {
  text-align: left;
  font-size: small !important;
  padding: 0;
}
.sign-out:hover {
  background-color: #f36363;
}

@media (max-width: 600px) {
  .makeStyles-drawerClose-8 {
    width: 65px !important;
  }
}
@media only screen and(max-width: 740px) {
  .makeStyles-drawerClose-8 {
    width: 65px !important;
  }
}

