.cont{
    padding: 40px;
    font-family: Poppins,sans-serif;
}
.header{
    font-size: 50px;
    
}
.clients{
    padding: 40px 0;
}
.borderBottom{
    border-top: 10px solid #edcd1f;
    width: 120px;
    margin: 0 auto;
    padding-bottom:40px ;
    /* color:#1e2d3b */
}
.Partners{
    padding: 2rem;
}
.clients{
    width: 80%;
    margin-left: 10%;
}
@media only screen and (min-width:1367px){
    .cont{
        width:100%
    }
}