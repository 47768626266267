@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.table thead th,
.table td {
  vertical-align: middle !important;
}

.requestContainer {
  display: flex;
  flex-direction: column;
}
.media {
  margin: 10px;
  text-align: center;
}
.sol_prov_stl {
  background: linear-gradient(0deg, #a3bfe0, #e8ebf8);
  margin: 0px 10px;
  text-align: center;
}
.sol_payer_stl {
  background: linear-gradient(0deg, #a3bce0, #eaebf8);
  margin: 0px 10px;
  text-align: center;
}
.media div {
  border: 0px solid;
}
.media ul {
  text-align: center;
}
.popup {
  position: fixed;
  /* Stay in place */
  z-index: 1010;
  /* Sit on top */
  padding-top: 150px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.MainPage {
  display: flex;
  flex-direction: column;
}

/* .link:hover,
.subHeader .link:hover {
  background-color: #3ab449;
  color: white !important;
}
.link{
  border-radius: 5px;
} */

.partners {
  margin-top: 50px;
  margin-bottom: 30px;
}

.partners h2 {
  color: #3ab449;
  text-align: center;
  padding-bottom: 2%;
}

.partnersLogs {
  display: flex;
  justify-content: space-around;
  margin: 8% 0 4% 0;
}
.par_img {
  width: 320px;
  height: 180px;
}
.team {
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.foo {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}
.foo td {
  padding: 20px;
  border: 0px;
  font-size: 20px;
}
.foo form {
  width: 100%;
}
.team h2 {
  display: flex;
  align-self: center;
  color: #3ab449;
  padding-bottom: 2%;
}
#errmsgminus {
  width: 50%;
  margin-right: 100px auto;
  color: "red";
}
#errmsgrequired {
  width: 50%;
  margin-right: 100px auto;
}
.formtitle {
  color: rgb(58, 180, 73);
}
.errmsg {
  width: 50%;
  margin-right: 100px auto;
}
#t2 {
  width: 70%;
  margin: 10px auto;
}
#t3 {
  width: 70%;
  margin: 10px auto;
}
.deleteicd {
  color: red;
  text-decoration: underline;
}

span.MuiIconButton-label,
span.MuiSwitch-thumb,
span.MuiTouchRipple-root,
span.MuiSwitch-track {
  margin-left: 0;
}

.user-managment-container {
  display: flex;
  flex-direction: column;
  margin: 2%;
}

.tablesContainer {
  display: inline-block;
}

.ActionDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.myheader {
  padding-top: 24px !important;
  padding-bottom: 35px !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.headerItems a {
  color: black !important;
  padding-left: 30px !important;
  font-size: 16px;
  color: #2d2d2d;
  font-weight: 500;
}

.caption p {
  font-size: 30px;
  font-weight: 500;
  color: #5fbfe8;
}

.caption {
  position: relative !important;
  top: 70px !important;
  text-align: center !important;
  right: 0 !important;
  padding-top: 0 !important;
  left: 0 !important;
}

.caption2 {
  position: relative !important;
  top: 70px !important;
  text-align: center !important;
  right: 0 !important;
  padding-top: 0 !important;
  left: 0 !important;
  margin-top: 53px !important;
}

.caption2 p {
  font-size: 30px;
  font-weight: 500;
  color: white;
}

.kayanCaption {
  font-size: larger;
}

.Justify {
  text-align: justify;
}

.grad {
  background-image: linear-gradient(to top, #a4c5ee, #e8ebf8);
}

.grad2 {
  height: 290px;
  background-color: #3ab449;
  background-image: linear-gradient(to left, #3ab449, #28aae1);
}

.grad h1 {
  text-align: center;
  padding-top: 40px;
}

.center {
  width: 60%;
  margin: auto;
}

.grad .center {
  text-align: center;
  font-size: 22px;
}

.whyKayan .whyKayanPar {
  color: rgb(255, 255, 255);
  position: relative;
  top: 100px;
  left: 66px;
  font-size: 31px;
}

.whyKayan ul {
  list-style: none !important;
}

svg {
  margin-left: 10px;
}

.ourSol {
  color: #3ab449;
  text-align: center;
  margin: 3% 0;
}

.whyKayan li {
  display: -webkit-box;
  margin-bottom: 20px;
  text-align: justify;
  font-size: initial;
}

.light {
  font-size: 30px;
  border-width: 1px;
  background: #28aae1;
  color: #ffffff;
  border-color: #333333;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  margin-right: 20px;
}

.circle {
  width: 290px;
  height: 290px;
  background-color: #64a9c6;
  border-radius: 50%;
  position: relative;
  top: 67px;
}
#link-dl-xls:hover {
  color: #6996ff;
  cursor: pointer;
  text-decoration: underline;
}

.SolDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.SubSolDiv {
  display: flex;
  flex-direction: column;
}

.solItem {
  width: 30vw;
  background-color: #28aae108;
  border-radius: 10px;
  margin: 2%;
}

.ClosedSolItemHeight {
  height: 50vh;
}

.OpenedSolItemHeight {
  height: 100vh;
}

.solItem h3 {
  text-align: center;
  padding-top: 10px;
  color: #3ab449;
}

.solItem ul {
  text-align: left;
}

.solItem p {
  text-align: justify;
  padding: 5vh;
}

.more {
  background: none;
  border: none;
  color: #4cbb59;
}

.feautersList {
  text-align: left;
}

.eshLog {
  max-width: 450px;
}

pre {
  text-align: left;
}

.Form {
  width: 50%;
  margin: auto;
}

label {
  color: #0000007d;
  float: left;
}

.header,
.subHeader {
  overflow: hidden;
  background-color: #ffffff;
  padding: 20px 10px;
}
.subHeader2 {
  height: 65px;
  background-color: #ffffff;
  padding: 20px 10px;
}
.cards_size {
  max-width: 400px;
  height: 300px;
  align-self: center;
}
.card_css {
  min-height: 100%;
  min-width: 300px;
  background: linear-gradient(to top, #a3bfe0, #e8ebf8);
}
.col_css {
  padding-left: 1px;
  padding-right: 1px;
}
.button_style {
  margin: 5px;
  width: 100%;
}
.header a,
.subHeader a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

.header .link:hover,
.subHeader .link:hover {
  background-color: #3ab449;
  color: white !important;
}

a:hover {
  text-decoration: none !important;
}

.header a.active {
  background-color: dodgerblue;
  color: white;
}

.header-right {
  float: right;
  margin-top: 10px;
}

.BayanLinks {
  width: 50%;
  margin: 40px auto;
  padding: 20px;
}

.BayanLinks a {
  display: block;
  color: white;
  background-color: #3ab449;
  width: 50%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 30px;
}

.BayanLinks a:hover {
  color: black;
}

.KayanHealthcareOffices {
  display: flex;
  flex-direction: row;
}

.OfficeStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.OtherPadding {
  padding-top: 2.5vh;
}

.locationIcon {
  display: flex;
}

.ceo {
  display: flex;
  flex-direction: row;
  padding: 0px 5%;
  align-self: center;
}

.ceoPhoto {
  width: 15vw;
  height: auto;
  align-self: center;
  border-radius: 50% !important;
  margin-bottom: 2vh;
}

.Footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: rgb(58, 180, 73);
  background-color: rgba(40, 170, 225, 0.12);
  padding: 1%;
}

/* Price Management Styles*/

.PriceManagementHomePage {
  margin-top: 10%;
  display: inline-grid;
}

.new-modal-form {
  width: 100%;
  height: 100%;
  box-shadow: none;
  margin-top: 0;
  padding: 0;
  text-align: center;
}
.new-modal-form button {
  margin-left: auto;
}

/* Mobile Design */
@media screen and (max-width: 1200px) {
  .header a,
  .subHeader a,
  .subHeader button {
    float: none;
    display: block;
    text-align: left;
  }

  .subHeader2 a {
    float: left;
    display: block;
    text-align: left;
    width: 100%;
  }
  .subHeader2 {
    height: 110px;
  }

  .header-right {
    float: left;
  }
  .caption2 {
    display: none;
    height: 50px;
  }
  .caption {
    display: none;
    height: 50px;
  }
  .kayanCaption {
    margin-top: 0px !important;
    float: left !important;
    max-width: 100% !important;
  }
  .clearOne {
    height: 320px !important;
  }
  .circle {
    margin-left: -11%;
  }
  .whyKayanCircle {
    margin-top: -89px;
    margin-left: 46px;
    margin-bottom: 100px;
  }

  .SolDiv {
    flex-direction: column;
  }

  .solItem {
    width: auto;
  }

  .ClosedSolItemHeight,
  .OpenedSolItemHeight {
    height: auto;
  }

  .solItem ul {
    text-align: center;
  }

  .partnersLogs {
    flex-direction: column;
  }

  .partnerLog {
    margin: 4% 0;
  }

  .eshLog {
    max-width: 300px;
  }
  .clearBody {
    height: 0px !important;
  }
  .copy {
    display: contents !important;
    text-align: left;
  }
  .info {
    display: contents !important;
    text-align: left;
  }

  .BtnsContainer {
    width: 100%;
  }

  .BayanLinks {
    width: 100%;
  }

  .BayanLinks a {
    width: 100%;
  }

  .center {
    width: auto;
  }

  .ceo {
    flex-direction: column;
  }

  .ceoPhoto {
    width: 40vw;
  }

  .Footer {
    flex-direction: column;
    padding: 2rem;
  }

  .KayanHealthcareOffices {
    flex-direction: column;
  }

  .OfficeStyle {
    margin-bottom: 1vh;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 420px) {
  .clearOne {
    height: 425px !important;
  }
}

@media screen and (max-width: 330px) {
  .clearOne {
    height: 500px !important;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.link-nav:hover {
  color: #edcd1f!important;
}
.link-nav{
  border-radius: 5px;
}

.line-nav{
  padding: 0;
  margin: 0;
}
.hoverMenue-nav{
  margin-top:0.3rem;
  padding: 0;
  width: 10.5rem;
  font-size: .95rem;
  background-color: #565b61;
  position: absolute
}
.dropDownItem-nav{
  width:100%;
  padding: 0.3rem 0.2rem;
}
.dropDownItem-nav:hover{
  background-color:#5d666f;
  font-size: 1.0rem;
}

.linkDropDown-nav{
  color: white;
  padding: .5rem;
  margin: 0;
  width: 100%;
}
.linkDropDown-nav:hover{
  color: white;
}
.dropDownItem-nav{
  padding: .7rem 0;
}
.kareem{
  min-height: 100vh;
}
@media only screen and (max-width:768px){
  .hoverMenue-nav{
    position: static;
    margin:0 auto;
    width: 90%;
  }

}
@media only screen and (max-width:763px){
  .kareem{
    flex-direction: column;
  }

}
/* .searchBox{
  height: 2.8rem;
} */

.partners_cont__3iIlB{
    padding: 40px;
    font-family: Poppins,sans-serif;
}
.partners_header__1XY-L{
    font-size: 50px;
    
}
.partners_clients__1PADh{
    padding: 40px 0;
}
.partners_borderBottom__199O_{
    border-top: 10px solid #edcd1f;
    width: 120px;
    margin: 0 auto;
    padding-bottom:40px ;
    /* color:#1e2d3b */
}
.partners_Partners__3ec5U{
    padding: 2rem;
}
.partners_clients__1PADh{
    width: 80%;
    margin-left: 10%;
}
@media only screen and (min-width:1367px){
    .partners_cont__3iIlB{
        width:100%
    }
}

.slick-prev:before,
.slick-next:before {
  color: #777777;
}
.detail-home{
    border-right: 2px solid white;
}
.team-home{
    height: 95%;
    width: 50%;
    padding: 30px;
    background-color: #f7f7f7;
    float: left;
    margin: 0;
    font-size: 18px;
    padding: 0 5em;
}
@media only screen and (min-width:1367px){
    .team-home{
        font-size: 20px;
    }
}
.ourTeam-home{
    width:20%;
    border-bottom: 4px dotted #64d2b5;
    border-style:dashed;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin-bottom: 20px;
}
@media only screen  and (min-width:781px) and (max-width:1366px){
    .team-home{
        padding: 0 3%;
    }
}
@media only screen and (max-width:1220px){
    .team-home{
        width: 70%;
    }
}
@media only screen and (max-width:780px){
    .detail-home{
        border-bottom: 2px solid white;
        border-right:0;
        padding-bottom:5px  ;
    }
    #about{
        height: 150%;
    }
    .team-home{
        width:100%;
        height: 100%;
        padding: 0 7%;
    }
    .ourTeam-home .p{
        font-size: 3em;
        width:100%
    }

}

.team_container__3svc8{
    height: 760px;
    text-align: left;
}

.team_header__2c4E2{
    width:100%;
    font-size: 45px;
    color: #509c35;
    margin-top: 1em;
    margin-bottom: .5em;
}

.team_text__32ipO{
    color: #509c35;
    border-bottom: 1px solid #509c35;
}
.team_company__3QLm2{
    background-color: #edcd1f;
    width: 100%;
    color:#3d5975;
    padding: 35px;
    text-align: center;
}
.team_companyHeader__1pXjE{
    font-size: 55px;
    font-weight: bold;
}
.team_companyText__fkHvD{
    font-size: 20px;
    font-weight: bold;
}
.team_detail__1t-UG{
    border-right: 2 px solid white;
}
@media only screen and (min-width:1367px) and (max-width:1700px){
    .team_container__3svc8{
        height: 950px;
    }

}


/* @media only screen and (max-width:600px){
    .detail{
        border-right: 0;
    }
    
} */

.home_container__j0nG5{
    text-align:center;
    width:100%;
    height: 650px;
    margin: 0 auto;
    color:#1e2d3b;
    /* background-color:#e1e0e0; */
    opacity:0.8;
    padding:10px;
    font-family: Poppins,sans-serif;
}
.home_header__H17xx{
    width:80%;
    margin: 10% 10%;
}
.home_active__2y45j li {
    display: none;
}
.home_header1__Yp5bu{
    color:#1e2d3b;
    font-weight: bold;
    font-size:2.5rem;
    line-height:1.3em;
    margin-bottom: 1.5em;
}

.home_provider__DYF_v{
    font-size: 1.35rem;
    font-weight: 600;
    margin-bottom: 1.8rem;
    color:#1e2d3b ;
}
@media only screen and (max-width:466px){
    .home_header__H17xx{
        margin: 5% 10%;
    }
    .home_header1__Yp5bu{
        font-size: 2rem;
    }
    .home_provider__DYF_v{
        font-size: 1.1rem;
    }
}
.services_cont__1ooZC{
    padding: 40px 0;
    font-family: Poppins,sans-serif;
}
.services_header__pq9v8{
    font-size: 50px;
}
/* .cards{
    margin: 0 auto;
    
} */

.services_borderBottom__2Ze5J{
    border-top: 10px solid #509c35;
    width: 120px;
    margin: 0 auto;
    padding-bottom:40px ;
    color:#1e2d3b
}

.services_cardHeader__2cYaW{
    font-size: 18px;
    color: #509c35;
}
/* .serviceImg{
    width:10em;
    height: 27vh;
} */
/* .column{
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
    position: relative;
    width: 20%;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
} */
.services_service__WcSNo{
    box-shadow:1px #1e2d3b;
    min-height: 24em;
}
/* @media only screen and (min-width:1367px){
    .service{
        min-height: 30em;
        width: 100%;
    }
} */
@media only screen  and (max-width:815px){

    .services_service__WcSNo{
        min-width:24rem
    }

}
.main-footer {
    color: white;
    background-color: #343a40;
    padding-top: 3em;
    width: 100%;
    text-align: left;
    margin-top: auto;
    /* position:absolute;
    left:0;
    bottom:0;
    right:0; */
}
.main-footer h4{
    font-size: 1.5rem;
}
.main-footer a{
    color: white;
}
.main-footer a:hover{
    color: #edcd1f;
}
.footer-icon{
    padding: 0;
    margin: 0;
}
/*.Header{
    display: flex;
    flex-grow: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}*/

.HomeTitle{
    
    color: white;
    text-align: center;
    padding: 1%;
    margin: 0%;
  }



  .logout{
    background-color:#28a745;
    color: white;
    border: none;
    font-size: medium;
    padding: 0.5vh 1vw;
    margin: 1%;
    border-radius: unset;

  }

  .dropdown button{
    border-radius: 50%;
    margin-top: 5px;
  }

form {
  width: 50%;
  margin: 30px auto;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
}
/* .form {
    width: 111%;
    margin-left: -16px;
    border: 0;
 
} */
.template {
  width: 60%;
  margin: 30px auto;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
}
.popup form {
  width: 100%;
  margin: -20px auto;
  box-shadow: none;
}

.popup1 form {
  width: 123%;
  margin: -20px auto;
  box-shadow: none;
}
.Form1 {
  border: 2px solid #f4511e;
}

.Form2 {
  border: 2px solid #1ef45e;
  background-color: #f2f2f29a;
  width: 100%;
}

.Form3 {
  border: 2px solid #000;
}
.form1 {
  position: relative;
  width: 90%;
  display: flex;
  border: 0;
  flex-flow: column wrap;
}
.form2 {
  position: relative;
  width: 70%;
  display: flex;
  border: 0;
  flex-flow: column wrap;
}
.form-sama {
  width: 100%;
  text-align: center;
  line-height: 30px;
}
.form-sama1 {
  width: 100%;
  text-align: center;
  line-height: 30px;
}
.flex-item1 {
  position: absolute;
  top: 0px;
  left: 0px;
}
.flex-item2 {
  margin: 5px;
  padding: 4px;
}
.flex-item3 {
  position: absolute;
  top: 0px;
  right: -20px;
}
.flex-item4 {
  position: absolute;
  top: 0px;
  right: 0px;
}
.flex-item5 {
  position: absolute;
  top: -10px;
  right: 0px;
}
.flex-item6 {
  position: absolute;
  top: -20px;
  right: 0px;
}

.flex-item7 {
  position: absolute;
  top: -40px;
  right: 244px;
}
.flex-item8 {
  position: absolute;
  top: -40px;
  right: 244px;
}
.flex-item9 {
  position: absolute;
  top: 40px;
  right: 430px;
}
.flex-item10 {
  position: absolute;
  top: 40px;
  right: 185px;
}
.flex-item11 {
  position: absolute;
  top: 38px;
  left: -40px;
}
.flex-item12 {
  position: absolute;
  top: 10px;
  left: 0px;
}
.flex-item13 {
  position: absolute;
  top: 35px;
  left: 188px;
}
.form-sama > div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.form-sama1 > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-sama1 {
  position: absolute;
  top: -2px;
  right: 10px;
}
.flex-sama2 {
  position: absolute;
  top: -2px;
  right: -190px;
}
.flex-sama3 {
  position: absolute;
  top: 45px;
  right: 0px;
}
.flex-sama4 {
  position: absolute;
  top: 45px;
  right: -210px;
}
.flex-sama5 {
  position: absolute;
  top: 100px;
  right: 0px;
}
.flex-sama6 {
  position: absolute;
  top: 100px;
  right: -210px;
}
.flex-sama7 {
  position: absolute;
  top: 0px;
  right: 200px;
}
.flex-sama8 {
  position: absolute;
  top: 0;
  right: -40px;
}

.Search {
  border: 2px solid #f2f2f29a;
  box-shadow: 0 12px 5px rgba(0, 0, 0, 0.24);
}

.popupForm {
  border: 0.5px solid #1ef45e;
  background-color: white;
  padding: 10px;
}

.form4 {
  margin-left: 0em;
  width: 65%;
  padding: 20px;
  margin: 5;
  outline: none;
}
.form5 {
  margin-left: 0em;
  padding: 20px;
  margin: 5;
  outline: none;
}
.some-page-wrapper {
  margin: 30px;
  background-color: red;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
}

@media only screen and (max-width: 1200px) {
  form,
  .template {
    width: 90%;
  }
}

button {
    
    align-content: center;
    border-radius: 0;
    text-align: center;
    /*padding: 1.2vh 3.3vw;*/
    margin: 2vh 0;
    font-size: 1.2vw;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}

.GenerateBtnStyle {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 23%;
    margin: 20px 10px;
    padding: 10px;
}
.GenerateBtnStyle1 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 100%;
    margin: 20px 10px;
    padding: 10px;
}
.GenerateBtnStyle2 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 32%;
    margin: 20px 10px;
    padding: 10px;
}
.GenerateBtnStyle3 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 68%;
    margin: 20px 10px;
    padding: -1px;
}
.GenerateBtnStyle4 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 60%;
    margin: 20px 10px;
    padding: -1px;
}
.GenerateBtnStyle5 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 20%;
    margin: 20px 10px;
    padding: -1px;
}
.GenerateBtnStyle6 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 42%;
    padding: -1px;
}
.GenerateBtnStyle7 {
    float: left;
    background-color: #dc3545;
    border: none;
    color: white;
    width: 42%;
    padding: -1px;
}
.GenerateBtnStyle8 {
    float: right;
    background-color: #3ab449;
    border: none;
    color: white;
    width: 42%;
    padding: -1px;
}
.GenerateBtnStyle9 {
    float: left;
    background-color: #dc3545;
    margin-left: 900px;
    border: none;
    color: white;
    width: 5%;
    padding: -1px;
}
.GenerateBtnStyle10 {
    float: right;
    background-color: #3ab449;
    margin-right: 900px;
    border: none;
    color: white;
    width: 5%;
    padding: -1px;
}
.GenerateBtnStyle11 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 15%;
    padding: -1px;
}

.DangerBtnStyle {
    display: inline-block;
    background-color: #dc3545;
    border: none;
    color: white !important;
    width: 23%;
    margin: 20px 10px;
    padding: 10px;
}

.SubmitReqStyle {
    background-color: #3ab449;
    border: none;
    color: #fff;
    padding: 10px;
    width: 20%;
    margin: 20px auto;
}

.BS1 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 23%;
    margin: 20px 10px;
    padding: 10px;
}

.dangerBS1 {
    background-color: #dc3545;
    border: none;
    color: white;
    width: 23%;
    margin: 20px 10px;
    padding: 10px;
}

.claimOrReq {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 20%;
    margin: 20px auto;
    padding: 10px;
}

.updateBtn{
    
    padding: 10px;
    width: 24%;
    margin:35px auto;
    margin-bottom: 15px;
    background-color: #3ab449;
    border: none;
    color: white;
    border-radius: unset !important;
}

.BS10 {
    padding: 10px;
    width: 30%;
    margin: auto;
    margin-bottom: 15px;
    background-color: #3ab449;
    border: none;
    color: white;
    border-radius: unset !important;
}

.BS10 a {
    color: white;
}

.BS11 {
    padding: 10px;
    /*  width: 20%; */
    width: 40%;
    margin: auto;
    background-color: #3ab449;
    border: none;
    color: white;
    border-radius: unset !important;
    margin-top: 10px;
}

.BS1:hover {
    cursor: pointer
}

.BS2 {
    background-color: white;
    color: #1ef45e;
    border: 1px solid #1ef45e;
    font-family: 'Abel', sans-serif;
    font-size: 30px;
}

.BS2:hover {
    background-color: #1ef45e;
    color: white;
    border: none;
}

.BS3 {
    background-color: gainsboro;
    color: #000;
    border: 1px solid #ccc;
    font-family: Georgia;
    font-size: 35px;
}

.BS3:hover {
    background-color: #a0bed0;
    box-shadow: 0 10px 5px rgba(0, 0, 0, 0.12), 0 10px 5px rgba(0, 0, 0, 0.24);
}

.BS4 {
    background-color: #28a745;
    color: white;
    border: none;
    font-size: medium;
    padding: 0.5vh 1vw;
    margin: 1%;
    border-radius: unset;
}

.BS5 {
    background-color: #f4511e;
    color: white;
    border: none;
    font-size: medium;
    width: 100%;
}

.BS7 {
    background-color: rgb(67, 183, 81);
    border: none;
    color: #ffffff;
    font-size: medium;
    border-radius: 5px;
    margin: 1%;
    padding: 1%;
}

.BS12 {
    padding: 10px;
    margin: 10px 10px;
    background-color: #3ab449;
    border: none;
    color: white;
    border-radius: unset !important;
}

.BS13 {
    padding: 25px;
    margin: 10px 10px;
    background-color: #3ab449;
    border: none;
    color: white;
    border-radius: unset !important;
}

.enabledStyle {
    color: white;
    background-color: #3ab449;
    width: 50%;
    margin: 10px auto;
    padding: 10px;
    border: none;
    display: block;
}

.disabledStyle {
    cursor: not-allowed;
    width: 50%;
    display: block;
    margin: 20px auto 10px;
    padding: 10px;
}

.fullWidth {
    width: -webkit-fill-available;
    margin: 1% 0;
}

.customWidth {
    width: 25%;
    margin: 1% 0;
}

.factorButton {
    text-align: center;
}

@media screen and (max-width: 2000px) {
    .BS1, .dangerBS1 {
        width: 30%;
    }
}
.AutoCompleteInput {
  padding: 1vh 0.6vw;
  font-size: 1.5vw;
  width: 100%;
}

.ACInputOnChangingStyle {
  display: flex;
  flex-wrap: wrap;
  padding: 1vh 0.6vw;
  width: 100%;
}

input[type="file"] {
  border: 1px solid #386cb152;
  display: block;
  margin: 30px auto;
  width: 100%;
}

.IT3 {
  display: block;
  width: 70%;
  margin: 10px auto;
  padding: 10px;
}

.ITRule {
  display: block;
  width: 100%;
  margin: 10px auto;
  padding: 10px;
}
.IT4 {
  display: block;
  width: 100%;
  margin: 10px auto;
  padding: 10px;
}
.Input1 {
  width: 28%;
}
.Input2 {
  width: 15%;
  margin-left: -980px;
}
.Input3 {
  width: 50%;
  margin-left: -525px;
}
.Input-sama {
  width: 100%;
  height: 40%;
}
.Input-sama1 {
  width: 110%;
  height: 40%;
  margin-left: -87%;
}
.Input-sama2 {
  width: 100%;
  height: 40%;
  margin-right: -69%;
}
.Input-sama3 {
  width: 100%;
  height: 40%;
  margin-left: -15%;
}
.Input-date {
  width: 100%;
  height: 40%;
}
.Input-sama5 {
  width: 178%;
}
.Input-sama6 {
  padding: 0px;
  width: 80%;
}
.Input-sama7 {
  padding: 1px;
  width: 60%;
}

@media only screen and (max-width: 1200px) {
  .AutoCompleteInput {
    font-size: 4vw;
  }
  .ACInputOnChangingStyle {
    font-size: 4vw;
  }

  input[type="file"] {
    width: 100%;
  }

  .IT3 {
    width: auto;
  }
}

.label {
    display: inline;
    text-align: center;
}
.label1 {
    float: left;
    width: 0em;
    margin-right: 0em;
    font-weight: bold;
    color:black;
    }
    .label2 {
        float: left;
        width: 18em;
        margin-right: 0em;
        font-weight: bold;
        color:black;
        }
  .label3 {
      float: right;
      margin-top: -355px;
    padding-right: 5px;
    width: 18em;
    font-weight: bold;
     color:black;
     }
     .label4 {
        float: right;
        margin-top: -405px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label5 {
        float: right;
        margin-right: -50px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label6 {
        float: right;
         margin-right: -50px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label7 {
        float: right;
        margin-top: -230px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label8 {
        float: right;
        margin-top: -270px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label9 {
        float: right;
        margin-top: -95px;
        margin-right: 500px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label10 {
        float: right;
        margin-top: -210px;
        margin-right: 70px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label11 {
        float: right;
        margin-top: -170px;
        margin-right: 70px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label12 {
        float: right;
        margin-top: -130px;
        margin-right: 70px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label13 {
        float: right;
     
       color:black;
       }
       .label14 {
        float: left;
     
       color:black;
       }



.BayanLabelStyle {
    width: unset !important;
    float: none !important;
}

.factorLabel {
    text-align: left;
}
a{
    text-decoration: none;
    cursor: pointer;
    
}
.Link1{
    color:#f4511e;
    font-size: 15px;
}

.Link2{
   
    color: #3ab449 !important;
    
}

.Link3{
    font-family:Georgia;
    font-size: 25px;
    color: black;
}
.BS10 {
    padding: 10px 35px;
    width: 30%;
    margin: auto 10px;
    margin-bottom: 15px;
    background-color: #3ab449;
    border: none;
    color: white !important;
    border-radius: unset !important;
}
.dangerBS1 {
        display:inline-block;
        background-color: #dc3545;
        border: none;
        color: white !important;
        width: 23%;
        margin: 20px 10px;
        padding: 10px;
   
}
/*.Radio{
   
        margin: 20px auto !important;
}
*/

input[type="radio"] {
        -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
        -moz-appearance: checkbox;    /* Firefox */
        -ms-appearance: checkbox;     /* not currently supported */
      }


     span{
        margin-left: 10px;
     }


     

      /*
 style={{
    width: "50%",
    margin: "10px auto",
    backgroundColor: "rgb(58, 180, 73)",
    color: "white",
    backgroundColor: "#3ab449",padding: "5px"}}

      */
.IT3 {
    display: block;
   
    width: 70%;
    margin: 10px auto !important;
    padding: 10px;
}
select {
  border: 1px solid #ccc;
  width: 70% !important;
  margin: 20px auto !important;
  display: block !important;
  padding: 10px;
  background-color: white;
  color: #000;
  cursor: pointer;
  outline: none;
}

.UpdateSelect, .CodesSelect {
  width: 100% !important;
  border: 1px solid #ccc;
  margin: 2vh 0;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}

.UpdateSelect, .CodesSelect1 {
  width: 36% !important;
  border: 1px solid #767676;
  margin: 2vh 0;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect2 {
  float: left;
  width: 10% !important;
  border: 1px solid #767676;
  margin: 2vh 0;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect3 {
  width: 100% !important;
  border: 1px solid #767676;
  margin: 2vh 0;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect4 {
  width: 20% !important;
  border: 1px solid #767676;
  margin: 4vh ;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect5 {
  width: 20% !important;
  border: 1px solid #767676;
  margin: 4vh ;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect6 {
  width: 20% !important;
  border: 1px solid #767676;
  margin: 4vh ;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect7 {
  width: 145% !important;
  border: 1px solid #767676;
  margin: 4vh ;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}

.UpdateSelect, .CodesSelect8 {
  width: 200% !important;
  border: 1px solid #767676;
  margin: 4vh ;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}


.fullWidthSelect {
  width: 100% !important;
  margin: 15px auto !important;
  padding: 10px;
}
.suggestions {
    
    list-style: none;
    
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    text-align: left;
    margin: 10px auto;
    width: 70%;
    
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #3ab449;
    color: white;
    cursor: pointer;
    
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #c4c4c4;
  }
ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    text-align: start;
    padding: 1%;
}

li {
    margin: 1%;
    cursor: pointer;
}


.errList {
    list-style-type: initial;
    margin: 0 2%;
}

.errList li {
    color: red;
    margin: 0.2%;
    cursor: auto;
}


.ProviderCodesMatched {
    border: 0.5px solid lightgray;
}

.ProviderCodesMatched li {
    border-bottom: 0.5px solid lightgray;
    padding: 0.5rem;
    margin: 0.3%;
}

.ProviderCodesMatched li:hover, .ProviderCodesMatched li:active {
    background-color: #3ab449;
    color: white;
}

.del {
    color: red;
    text-decoration: underline;
    cursor: pointer;
}

th {
    padding: 5px 17px;
}

thead {
    background: #3ab449;
    color: white;
}

/* Sanad Nav Bar  */

.SanadNavBar {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f3f3f3;
}

.SanadNavBar li {
    float: left;
    margin: 0px !important;
}

.SanadNavBar li:hover {
    background-color: #3ab449;
}

.SanadNavBar li a {
    display: block;
    color: #666;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.SanadNavBar li:hover a {
    color: white;
}
.CheckBox {
    width: 40em;
    margin-left:-360px;
    }
    .CheckBox1 {
        width: 40em;
        float: right;
        margin-top: -400px;
        }
        .CheckBox2 {
            width: 40em;
            float: right;
            margin-top: -350px;
            }
            .CheckBox3 {
                width: 40em;
                float: right;
                margin-top: -225px;
                }
                .CheckBox4 {
                    width: 40em;
                    float: right;
                    margin-top: -265px;
                    }
                    .CheckBox5 {
                        width: 40em;
                        float: right;
                        margin-top: -90px;
                        margin-right: 450px;
                        }
                        .CheckBox6 {
                            width: 40em;
                            float: right;
                            margin-top: -204px;
                            margin-right: 0px;
                            }
                            .CheckBox7 {
                                width: 40em;
                                float: right;
                                margin-top: -165px;
                                margin-right: 0px;
                                }
                                .CheckBox8 {
                                    width: 40em;
                                    float: right;
                                    margin-top: -125px;
                                    margin-right: 0px;
                                    }
.suggestions {
    
    list-style: none;
    
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    text-align: left;
    margin: 10px auto;
    width: 70%;
    
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #3ab449;
    color: white;
    cursor: pointer;
    
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #c4c4c4;
  }
.suggestions {
    
    list-style: none;
    
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    text-align: left;
    margin: 10px auto;
    width: 70%;
    
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #3ab449;
    color: white;
    cursor: pointer;
    
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #c4c4c4;
  }
ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    text-align: start;
    padding: 1%;
}

li {
    margin: 1%;
    cursor: pointer;
}


.errList {
    list-style-type: initial;
    margin: 0 2%;
}

.errList li {
    color: red;
    margin: 0.2%;
    cursor: auto;
}


.ProviderCodesMatched {
    border: 0.5px solid lightgray;
}

.ProviderCodesMatched li {
    border-bottom: 0.5px solid lightgray;
    padding: 0.5rem;
    margin: 0.3%;
}

.ProviderCodesMatched li:hover, .ProviderCodesMatched li:active {
    background-color: #3ab449;
    color: white;
}

.del {
    color: red;
    text-decoration: underline;
    cursor: pointer;
}

th {
    padding: 5px 17px;
}

thead {
    background: #3ab449;
    color: white;
}

/* Sanad Nav Bar  */

.SanadNavBar {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f3f3f3;
}

.SanadNavBar li {
    float: left;
    margin: 0px !important;
}

.SanadNavBar li:hover {
    background-color: #3ab449;
}

.SanadNavBar li a {
    display: block;
    color: #666;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.SanadNavBar li:hover a {
    color: white;
}
.suggestions {
    
    list-style: none;
    
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    text-align: left;
    margin: 10px auto;
    width: 70%;
    
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #3ab449;
    color: white;
    cursor: pointer;
    
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #c4c4c4;
  }
:root {
    /* Colors: */
    --table-color: #ffffff;
  
    --menu-font-077756: #077756;
  
    --back-color-ebebeb: white;
  }
  
  body {
    background: white !important;
    background: var(--back-color-ebebeb) !important;
  }
  
  /* barra geral */
  .MuiDrawer-paper {
    background-color: #077756 !important;
    background-color: var(--menu-font-077756) !important;
    color: white !important;
    color: var(--back-color-ebebeb) !important;
    box-shadow: 5px 5px 10px 0px rgba(50, 50, 50, 0.75) !important;
    border: none !important;
  }
  .tabActive {
    background-color: #3ab449;
  }
  .tab1 {
    margin-left: 0.7rem;
    margin-top: 0.2rem;
  }
  .tabActive1 {
    margin-left: 0.7rem;
    margin-top: 0.2rem;
    background-color: #3ab449;
  }
  .MuiToolbar-root {
    background-color: white;
    background-color: var(--back-color-ebebeb);
  }
  
  .MuiListItemIcon-root svg {
    color: white;
    color: var(--back-color-ebebeb);
  }
  .MuiList-root {
    text-align: left;
    font-size: small !important;
    padding: 0;
  }
  .sign-out:hover {
    background-color: #f36363;
  }
 
  @media (max-width: 600px) {
    .makeStyles-drawerClose-8 {
      width: 65px !important;
    }
  }
  @media only screen and(max-width: 740px) {
    .makeStyles-drawerClose-8 {
      width: 65px !important;
    }
  }
  
  
.styleBayan_selectType__2kadb{
    text-align:left;
    width: 100% !important;
    margin-top: 11px !important;
    border: 1px solid grey;
 
}
/* barra geral */
/* .MuiDrawer-paper {
  background-color: white !important;
  color: var(--back-color-ebebeb) !important;
  box-shadow: 5px 5px 10px 0px rgba(50, 50, 50, 0.75) !important;
  border: none !important;
} */

.MuiDrawer-docked .MuiPaper-elevation {
  background-color: #1976d2 !important;
}

.css-rqglhn-MuiTable-root caption {
  caption-side: top !important;
}

table {
  width: 100%;
}


.Kayanthead {
  font-family: 'Abel', sans-serif;
}

tbody {
  font-family: arial, sans-serif;
  background-color: white;
}

td, th {
  padding: 0.5em;
  font-size: 0.85vw;
  font-weight: 700;
  border: 1px solid #dee2e6;
}

tr {
  background-color: rgba(0,0,0,.05);
}

tr:nth-child(even) {
  background-color: white;
}

.singleTrBody {
  background-color: white;
}

:root {
  /* Colors: */
  --table-color: #ffffff;

  --menu-font-077756: #077756;

  --back-color-ebebeb: white;
}

body {
  background: white !important;
  background: var(--back-color-ebebeb) !important;
}

/* barra geral */
.MuiDrawer-paper {
  background-color: #077756 !important;
  background-color: var(--menu-font-077756) !important;
  color: white !important;
  color: var(--back-color-ebebeb) !important;
  box-shadow: 5px 5px 10px 0px rgba(50, 50, 50, 0.75) !important;
  border: none !important;
}
.tabActive {
  background-color: #3ab449;
}
.tab1 {
  margin-left: 0.7rem;
  margin-top: 0.2rem;
}
.tabActive1 {
  margin-left: 0.7rem;
  margin-top: 0.2rem;
  background-color: #3ab449;
}
.MuiToolbar-root {
  background-color: white;
  background-color: var(--back-color-ebebeb);
}

.MuiListItemIcon-root svg {
  color: white;
  color: var(--back-color-ebebeb);
}
.MuiList-root {
  text-align: left;
  font-size: small !important;
  padding: 0;
}
.sign-out:hover {
  background-color: #f36363;
}

@media (max-width: 600px) {
  .makeStyles-drawerClose-8 {
    width: 65px !important;
  }
}
@media only screen and(max-width: 740px) {
  .makeStyles-drawerClose-8 {
    width: 65px !important;
  }
}


form {
  width: 50%;
  margin: 30px auto;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
}
.ruleScreen {
  width: 50%;
  margin: 22px auto;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
}

.kareemBenefitsFilters {
  width: 90%;
  margin: 22px auto;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
}
/* .form {
    width: 111%;
    margin-left: -16px;
    border: 0;
 
} */
.template {
  width: 60%;
  margin: 30px auto;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
}
.popup form {
  width: 100%;
  margin: -20px auto;
  box-shadow: none;
}

.popup1 form {
  width: 123%;
  margin: -20px auto;
  box-shadow: none;
}
.Form1 {
  border: 2px solid #f4511e;
}

.Form2 {
  border: 2px solid #1ef45e;
  background-color: #f2f2f29a;
  width: 100%;
}

.Form3 {
  border: 2px solid #000;
}
.form1 {
  position: relative;
  width: 90%;
  display: flex;
  border: 0;
  flex-flow: column wrap;
}
.form2 {
  position: relative;
  width: 70%;
  display: flex;
  border: 0;
  flex-flow: column wrap;
}
.form-sama {
  width: 100%;
  text-align: center;
  line-height: 30px;
}
.form-sama1 {
  width: 100%;
  text-align: center;
  line-height: 30px;
}
.flex-item1 {
  position: absolute;
  top: 0px;
  left: 0px;
}
.flex-item2 {
  margin: 5px;
  padding: 4px;
}
.flex-item3 {
  position: absolute;
  top: 0px;
  right: -20px;
}
.flex-item4 {
  position: absolute;
  top: 0px;
  right: 0px;
}
.flex-item5 {
  position: absolute;
  top: -10px;
  right: 0px;
}
.flex-item6 {
  position: absolute;
  top: -20px;
  right: 0px;
}

.flex-item7 {
  position: absolute;
  top: -40px;
  right: 244px;
}
.flex-item8 {
  position: absolute;
  top: -40px;
  right: 244px;
}
.flex-item9 {
  position: absolute;
  top: 40px;
  right: 430px;
}
.flex-item10 {
  position: absolute;
  top: 40px;
  right: 185px;
}
.flex-item11 {
  position: absolute;
  top: 38px;
  left: -40px;
}
.flex-item12 {
  position: absolute;
  top: 10px;
  left: 0px;
}
.flex-item13 {
  position: absolute;
  top: 35px;
  left: 188px;
}
.form-sama > div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.form-sama1 > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-sama1 {
  position: absolute;
  top: -2px;
  right: 10px;
}
.flex-sama2 {
  position: absolute;
  top: -2px;
  right: -190px;
}
.flex-sama3 {
  position: absolute;
  top: 45px;
  right: 0px;
}
.flex-sama4 {
  position: absolute;
  top: 45px;
  right: -210px;
}
.flex-sama5 {
  position: absolute;
  top: 100px;
  right: 0px;
}
.flex-sama6 {
  position: absolute;
  top: 100px;
  right: -210px;
}
.flex-sama7 {
  position: absolute;
  top: 0px;
  right: 200px;
}
.flex-sama8 {
  position: absolute;
  top: 0;
  right: -40px;
}

.Search {
  border: 2px solid #f2f2f29a;
  box-shadow: 0 12px 5px rgba(0, 0, 0, 0.24);
}

.popupForm {
  border: 0.5px solid #1ef45e;
  background-color: white;
  padding: 10px;
}

.form4 {
  margin-left: 0em;
  width: 65%;
  padding: 20px;
  margin: 5;
  outline: none;
}
.form5 {
  margin-left: 0em;
  padding: 20px;
  margin: 5;
  outline: none;
}
.some-page-wrapper {
  margin: 30px;
  background-color: red;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
}

@media only screen and (max-width: 1200px) {
  form,
  .template {
    width: 90%;
  }
}

.plan_tdBody__1lfpk td{
    padding: 0;
    background-color: white;
}
.plan_table1__2yVjt{
    margin: 50px auto;
    width: 75%;
}
.plan_tbodyTable2__EA14j{
    padding: 5px;
    background-color: white;
}
.researchPage_container__3BbnL{
    width: 100%;
    font-family: Poppins,sans-serif;
    top: 0;
}
.researchPage_top__31rez{
    width: 100%;
    height: 660px;
}
.researchPage_right__3PDAe{
    text-align:left;
    color:#1e2d3b;
    background-color:#e1e0e0;
    opacity:0.9;
    width:50%;
    float: right;
    height: 660px;
    padding: 40px 10px;
}
.researchPage_bottom__2Wa_C{
    text-align:center;
    height: 650px;
}
.researchPage_textImage__qV6p1{
    padding: 10% 10%;
}
.researchPage_header2__31LU3{
    color:#1e2d3b;
    font-size:50px;
    text-transform:uppercase;
    line-height:1.3em;
}
.researchPage_text2__1nj99{
    font-size:20px;
    color:black;
    text-transform:uppercase;
    font-weight:bold;
    margin-left: 10%;
}
.researchPage_header3__1I63w{
    font-size:33px;
    text-transform:uppercase;
    line-height:1.3em;
    font-weight:bold;
    margin-left: 10%;
}
.researchPage_info__388yZ{
    font-size:16px;
    margin-left: 10%;
}
.researchPage_text3__4kAoN{
    font-size:16px;
    margin-left: 10%;
}
@media only screen and (max-width:705px) {
    .researchPage_right__3PDAe{
        width:100%;
        height: 680px;
    }
    
}
@media only screen and (max-width:420px) {
    .researchPage_header2__31LU3{
        font-size:35px;
    }
    .researchPage_text2__1nj99{
        font-size:16px;
    }
    
}
.allSol h2 {
  font-size: 3rem;
  /* margin-bottom: 10px; */
  color: #1e2d3b;
  /* text-align: left; */
  /* padding-left:8% ; */
}
.bloc-tabs {
  display: flex;
}
.tabs {
  color: #1e2d3b;
  font-size: 1.5rem;
  font-weight: 600;
  background: red;
  padding: 15px;
  text-align: center;
  width: 50%;
  background: white;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  background: white;
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: #edcd1f;
}

button {
  border: none;
}

.movement {
  border: none;
  background-color: #1e2d3b !important;
  color: #edcd1f !important;
}

.movement:hover {
  background-color: #edcd1f !important;
  color: #1e2d3b !important;
}

.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 10px;
  background: #edcd1f;
  margin-bottom: 5px;
}
/* .content p {
    width: 100%;
    height: 100%;
  } */
.active-content {
  display: block;
}
.text {
  text-align: left;
  font-size: 1.07em;
}
.flexinfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}
.left-provider {
  width: 50%;
  padding: 4em 0;
  float: left;
}
.spantext {
  font-size: 1.5em;
  float: left;
  margin-left: 0;
}
.right-provider {
  width: 40%;
  display: block;
  float: right;
}
.features {
  float: left;
  text-align: left;
}
.features ol li {
  text-decoration: none;
  font-size: 0.9em;
}
.img {
  width: 50%;
  height: 50%;
  margin-top: 2.5em;
}
.features ul li {
  font-size: 1.05em;
  text-decoration: none;
  float: left;
}

@media only screen and (max-width: 959px) {
  .right-provider {
    display: none;
  }
  .left-provider {
    width: 100%;
  }
}
.btns {
  padding: 0 0 3rem 0;
}
@media only screen and (max-width: 520px) {
  /* .img{
        width:60%;
        height: 45%;
        margin-top: 2.5em;
    } */

  .leftBtn {
    margin-bottom: 3em;
  }
  .tabs {
    width: 20%;
    font-size: 0.9rem;
  }
}

.container22 {
    padding: 40px 0;
    width:80%;
    margin-left: 10%;
    color: #1e2d3b;
    font-family: Poppins, sans-serif;
}

.heading-team {
  font-size: 50px;
  color: #1e2d3b;
  text-transform: uppercase;
}
.borderBottom-team{
  border-top: 10px solid #edcd1f;
  width: 120px;
  margin: 0 auto;
  padding-bottom:40px ;
  color:#1e2d3b
}

.heading-team span {
  font-style: italic;
  font-size: 30px;
}

.profiles-team {
  /* display: flex; */
  /* justify-content: space-around; */
  margin: 20px 0;
  width: 100%;
  padding: 0;
}
.profile-team{
  width: 100%;
  padding: 0;
  /* height: 25rem; */
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;
}

.profile-team h3{
  text-align: left;
  margin-top: 0rem;
}
.profile-team p{
  padding: 0;
  text-align: left;
}
.profile-team h5 {
  font-size: 16px;
  /* font-weight: 100; */
  letter-spacing: 3px;
  color: #1e2d3b;
  text-align: left;
}

/* .profile p {
  font-size: 16px;
  margin-top: 20px;
  text-align: left;
} */
.profile-team .profile-img {
  height: 16rem;
  width: 14rem;
  padding: 0;
  margin: 0;
  /* border-radius: 2%; */
  /* filter: grayscale(100%); */
}
.member-pic{
  width:30%;
  margin: 0;
}
.item-team{
  line-height:2.5rem;
  font-size: 0.90rem;
  font-weight:600 ;
  margin-bottom: 10%;

}
.member-contact{
  /* padding: 0 0 0 0rem; */
  /* padding-right: 0.7rem; */
  width: 30%;
  margin: 0;
}
.member-info{
  width:40%;
  margin: 0;
}
/* .profile:hover .profile-img {
  filter: grayscale(0);
} */

.user-name-team {
  margin-top: 30px;
  font-size: 30px;
}

.footer-icon-team{
  margin-right: .3rem;
}
.email-team{
  color:#1e2d3b
}
@media screen and (max-width:941px) {
  .profile-team{
    flex-direction: column;
  }
  .member-pic{
    width:100%;
    margin: 0;
  }
  .member-info{
    width:100%;
    margin: 0;
  }
  .member-contact{
    width:100%;
    margin: 0;
  }
  .member-contact li{
    text-align: center;
  }
  .profile-team h5 {
    text-align: center;
  }
  .profile-team h3 {
    text-align: center;
  }
  .profile-team p{
    padding: 0;
    text-align: center;
  }
  .item-team{
    margin-bottom: 0;
  }
}
/* @media only screen and (max-width: 1150px) {
  .profiles {
    flex-direction: column;
  }

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile p {
    text-align: center;
    margin: 20px 60px 80px 60px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .heading {
    font-size: 40px;
    text-align: center;
  }

  .heading span {
    font-size: 15px;
  }

  .profiles {
    margin: 20px 0;
  }

  .profile p {
    margin: 20px 10px 80px 10px;
  }
} */

.contactPage_cont__3kBv8{
    padding: 40px 10%;
    font-family: Poppins,sans-serif;
    /* background-color: #f7f7f7; */
    width: 100%;
    text-align: left;
    min-height: 40rem;
}
.contactPage_content__uKDyC{
    font-size: 1.2rem;
    font-weight: 600;
    line-height:1.3rem;
}
.contactPage_header__2Lw0b{
    font-size: 2.5rem;
    margin-bottom: .9rem;
}
.contactPage_row__3B_SW{
    display: flex;
    width:65%;
    height:22rem;
    margin-left:17.5%;
    margin-bottom: 5rem;
    box-sizing: border-box;

}
.contactPage_column__3fXjq{
    padding-left:1rem;
    padding-right:1rem;
    background-color:#f5f5f5;
    width:50%;
    padding-top:1.5rem;
    border-radius:0.3rem;
    margin-right:0;
    border-right:0
}
.contactPage_column1__2usf9{
    width: 50%;
}
.contactPage_map__3Oif-{
    width:100%;
    height: 100%;
    border: 1px solid white;
    padding: 0;
}
@media only screen and (max-width:786px){
    .contactPage_row__3B_SW{
        flex-direction: column;
        width: 90%;
        height: 30rem;
        margin-left: 5%;
        height: 28rem;

    }
    /* .map{
        height: %;
    } */
    .contactPage_column__3fXjq{
        width: 100%;
        
    }
    .contactPage_column1__2usf9{
        width: 100%;
    }

}
.Kareem_KareemLinks__2gvDA {
  width: 50%;
  margin: 40px auto;
  padding: 20px;
    /* min-height: 350px; */
}

.Kareem_KareemLinks__2gvDA a {
  display: block;
  color: white;
  background-color: #3ab449;
  width: 50%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 30px;
}

.Kareem_KareemLinks__2gvDA a:hover {
    color: black;
}
.Kareem_registerDiv__2HWHQ{
    margin: 3.5rem 0;
}
.Kareem_col__145Cx{
  width: 50%;
}
/* .show {
    visibility: visible;
    animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
  }
  
  .hide {
    visibility: hidden;
  }
  
  @keyframes fadeIn {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  } */
