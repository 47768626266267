.label {
    display: inline;
    text-align: center;
}
.label1 {
    float: left;
    width: 0em;
    margin-right: 0em;
    font-weight: bold;
    color:black;
    }
    .label2 {
        float: left;
        width: 18em;
        margin-right: 0em;
        font-weight: bold;
        color:black;
        }
  .label3 {
      float: right;
      margin-top: -355px;
    padding-right: 5px;
    width: 18em;
    font-weight: bold;
     color:black;
     }
     .label4 {
        float: right;
        margin-top: -405px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label5 {
        float: right;
        margin-right: -50px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label6 {
        float: right;
         margin-right: -50px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label7 {
        float: right;
        margin-top: -230px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label8 {
        float: right;
        margin-top: -270px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label9 {
        float: right;
        margin-top: -95px;
        margin-right: 500px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label10 {
        float: right;
        margin-top: -210px;
        margin-right: 70px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label11 {
        float: right;
        margin-top: -170px;
        margin-right: 70px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label12 {
        float: right;
        margin-top: -130px;
        margin-right: 70px;
      padding-right: 5px;
      width: 18em;
      font-weight: bold;
       color:black;
       }
       .label13 {
        float: right;
     
       color:black;
       }
       .label14 {
        float: left;
     
       color:black;
       }



.BayanLabelStyle {
    width: unset !important;
    float: none !important;
}

.factorLabel {
    text-align: left;
}