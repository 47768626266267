@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");


.container22 {
    padding: 40px 0;
    width:80%;
    margin-left: 10%;
    color: #1e2d3b;
    font-family: Poppins, sans-serif;
}

.heading-team {
  font-size: 50px;
  color: #1e2d3b;
  text-transform: uppercase;
}
.borderBottom-team{
  border-top: 10px solid #edcd1f;
  width: 120px;
  margin: 0 auto;
  padding-bottom:40px ;
  color:#1e2d3b
}

.heading-team span {
  font-style: italic;
  font-size: 30px;
}

.profiles-team {
  /* display: flex; */
  /* justify-content: space-around; */
  margin: 20px 0;
  width: 100%;
  padding: 0;
}
.profile-team{
  width: 100%;
  padding: 0;
  /* height: 25rem; */
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;
}

.profile-team h3{
  text-align: left;
  margin-top: 0rem;
}
.profile-team p{
  padding: 0;
  text-align: left;
}
.profile-team h5 {
  font-size: 16px;
  /* font-weight: 100; */
  letter-spacing: 3px;
  color: #1e2d3b;
  text-align: left;
}

/* .profile p {
  font-size: 16px;
  margin-top: 20px;
  text-align: left;
} */
.profile-team .profile-img {
  height: 16rem;
  width: 14rem;
  padding: 0;
  margin: 0;
  /* border-radius: 2%; */
  /* filter: grayscale(100%); */
}
.member-pic{
  width:30%;
  margin: 0;
}
.item-team{
  line-height:2.5rem;
  font-size: 0.90rem;
  font-weight:600 ;
  margin-bottom: 10%;

}
.member-contact{
  /* padding: 0 0 0 0rem; */
  /* padding-right: 0.7rem; */
  width: 30%;
  margin: 0;
}
.member-info{
  width:40%;
  margin: 0;
}
/* .profile:hover .profile-img {
  filter: grayscale(0);
} */

.user-name-team {
  margin-top: 30px;
  font-size: 30px;
}

.footer-icon-team{
  margin-right: .3rem;
}
.email-team{
  color:#1e2d3b
}
@media screen and (max-width:941px) {
  .profile-team{
    flex-direction: column;
  }
  .member-pic{
    width:100%;
    margin: 0;
  }
  .member-info{
    width:100%;
    margin: 0;
  }
  .member-contact{
    width:100%;
    margin: 0;
  }
  .member-contact li{
    text-align: center;
  }
  .profile-team h5 {
    text-align: center;
  }
  .profile-team h3 {
    text-align: center;
  }
  .profile-team p{
    padding: 0;
    text-align: center;
  }
  .item-team{
    margin-bottom: 0;
  }
}
/* @media only screen and (max-width: 1150px) {
  .profiles {
    flex-direction: column;
  }

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile p {
    text-align: center;
    margin: 20px 60px 80px 60px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .heading {
    font-size: 40px;
    text-align: center;
  }

  .heading span {
    font-size: 15px;
  }

  .profiles {
    margin: 20px 0;
  }

  .profile p {
    margin: 20px 10px 80px 10px;
  }
} */
