.KareemLinks {
  width: 50%;
  margin: 40px auto;
  padding: 20px;
    /* min-height: 350px; */
}

.KareemLinks a {
  display: block;
  color: white;
  background-color: #3ab449;
  width: 50%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 30px;
}

.KareemLinks a:hover {
    color: black;
}
.registerDiv{
    margin: 3.5rem 0;
}
.col{
  width: 50%;
}
/* .show {
    visibility: visible;
    animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
  }
  
  .hide {
    visibility: hidden;
  }
  
  @keyframes fadeIn {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  } */