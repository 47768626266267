button {
    
    align-content: center;
    border-radius: 0;
    text-align: center;
    /*padding: 1.2vh 3.3vw;*/
    margin: 2vh 0;
    font-size: 1.2vw;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}

.GenerateBtnStyle {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 23%;
    margin: 20px 10px;
    padding: 10px;
}
.GenerateBtnStyle1 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 100%;
    margin: 20px 10px;
    padding: 10px;
}
.GenerateBtnStyle2 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 32%;
    margin: 20px 10px;
    padding: 10px;
}
.GenerateBtnStyle3 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 68%;
    margin: 20px 10px;
    padding: -1px;
}
.GenerateBtnStyle4 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 60%;
    margin: 20px 10px;
    padding: -1px;
}
.GenerateBtnStyle5 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 20%;
    margin: 20px 10px;
    padding: -1px;
}
.GenerateBtnStyle6 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 42%;
    padding: -1px;
}
.GenerateBtnStyle7 {
    float: left;
    background-color: #dc3545;
    border: none;
    color: white;
    width: 42%;
    padding: -1px;
}
.GenerateBtnStyle8 {
    float: right;
    background-color: #3ab449;
    border: none;
    color: white;
    width: 42%;
    padding: -1px;
}
.GenerateBtnStyle9 {
    float: left;
    background-color: #dc3545;
    margin-left: 900px;
    border: none;
    color: white;
    width: 5%;
    padding: -1px;
}
.GenerateBtnStyle10 {
    float: right;
    background-color: #3ab449;
    margin-right: 900px;
    border: none;
    color: white;
    width: 5%;
    padding: -1px;
}
.GenerateBtnStyle11 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 15%;
    padding: -1px;
}

.DangerBtnStyle {
    display: inline-block;
    background-color: #dc3545;
    border: none;
    color: white !important;
    width: 23%;
    margin: 20px 10px;
    padding: 10px;
}

.SubmitReqStyle {
    background-color: #3ab449;
    border: none;
    color: #fff;
    padding: 10px;
    width: 20%;
    margin: 20px auto;
}

.BS1 {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 23%;
    margin: 20px 10px;
    padding: 10px;
}

.dangerBS1 {
    background-color: #dc3545;
    border: none;
    color: white;
    width: 23%;
    margin: 20px 10px;
    padding: 10px;
}

.claimOrReq {
    background-color: #3ab449;
    border: none;
    color: white;
    width: 20%;
    margin: 20px auto;
    padding: 10px;
}

.updateBtn{
    
    padding: 10px;
    width: 24%;
    margin:35px auto;
    margin-bottom: 15px;
    background-color: #3ab449;
    border: none;
    color: white;
    border-radius: unset !important;
}

.BS10 {
    padding: 10px;
    width: 30%;
    margin: auto;
    margin-bottom: 15px;
    background-color: #3ab449;
    border: none;
    color: white;
    border-radius: unset !important;
}

.BS10 a {
    color: white;
}

.BS11 {
    padding: 10px;
    /*  width: 20%; */
    width: 40%;
    margin: auto;
    background-color: #3ab449;
    border: none;
    color: white;
    border-radius: unset !important;
    margin-top: 10px;
}

.BS1:hover {
    cursor: pointer
}

.BS2 {
    background-color: white;
    color: #1ef45e;
    border: 1px solid #1ef45e;
    font-family: 'Abel', sans-serif;
    font-size: 30px;
}

.BS2:hover {
    background-color: #1ef45e;
    color: white;
    border: none;
}

.BS3 {
    background-color: gainsboro;
    color: #000;
    border: 1px solid #ccc;
    font-family: Georgia;
    font-size: 35px;
}

.BS3:hover {
    background-color: #a0bed0;
    box-shadow: 0 10px 5px rgba(0, 0, 0, 0.12), 0 10px 5px rgba(0, 0, 0, 0.24);
}

.BS4 {
    background-color: #28a745;
    color: white;
    border: none;
    font-size: medium;
    padding: 0.5vh 1vw;
    margin: 1%;
    border-radius: unset;
}

.BS5 {
    background-color: #f4511e;
    color: white;
    border: none;
    font-size: medium;
    width: 100%;
}

.BS7 {
    background-color: rgb(67, 183, 81);
    border: none;
    color: #ffffff;
    font-size: medium;
    border-radius: 5px;
    margin: 1%;
    padding: 1%;
}

.BS12 {
    padding: 10px;
    margin: 10px 10px;
    background-color: #3ab449;
    border: none;
    color: white;
    border-radius: unset !important;
}

.BS13 {
    padding: 25px;
    margin: 10px 10px;
    background-color: #3ab449;
    border: none;
    color: white;
    border-radius: unset !important;
}

.enabledStyle {
    color: white;
    background-color: #3ab449;
    width: 50%;
    margin: 10px auto;
    padding: 10px;
    border: none;
    display: block;
}

.disabledStyle {
    cursor: not-allowed;
    width: 50%;
    display: block;
    margin: 20px auto 10px;
    padding: 10px;
}

.fullWidth {
    width: -webkit-fill-available;
    margin: 1% 0;
}

.customWidth {
    width: 25%;
    margin: 1% 0;
}

.factorButton {
    text-align: center;
}

@media screen and (max-width: 2000px) {
    .BS1, .dangerBS1 {
        width: 30%;
    }
}