.AutoCompleteInput {
  padding: 1vh 0.6vw;
  font-size: 1.5vw;
  width: 100%;
}

.ACInputOnChangingStyle {
  display: flex;
  flex-wrap: wrap;
  padding: 1vh 0.6vw;
  width: 100%;
}

input[type="file"] {
  border: 1px solid #386cb152;
  display: block;
  margin: 30px auto;
  width: 100%;
}

.IT3 {
  display: block;
  width: 70%;
  margin: 10px auto;
  padding: 10px;
}

.ITRule {
  display: block;
  width: 100%;
  margin: 10px auto;
  padding: 10px;
}
.IT4 {
  display: block;
  width: 100%;
  margin: 10px auto;
  padding: 10px;
}
.Input1 {
  width: 28%;
}
.Input2 {
  width: 15%;
  margin-left: -980px;
}
.Input3 {
  width: 50%;
  margin-left: -525px;
}
.Input-sama {
  width: 100%;
  height: 40%;
}
.Input-sama1 {
  width: 110%;
  height: 40%;
  margin-left: -87%;
}
.Input-sama2 {
  width: 100%;
  height: 40%;
  margin-right: -69%;
}
.Input-sama3 {
  width: 100%;
  height: 40%;
  margin-left: -15%;
}
.Input-date {
  width: 100%;
  height: 40%;
}
.Input-sama5 {
  width: 178%;
}
.Input-sama6 {
  padding: 0px;
  width: 80%;
}
.Input-sama7 {
  padding: 1px;
  width: 60%;
}

@media only screen and (max-width: 1200px) {
  .AutoCompleteInput {
    font-size: 4vw;
  }
  .ACInputOnChangingStyle {
    font-size: 4vw;
  }

  input[type="file"] {
    width: 100%;
  }

  .IT3 {
    width: auto;
  }
}
