.detail-home{
    border-right: 2px solid white;
}
.team-home{
    height: 95%;
    width: 50%;
    padding: 30px;
    background-color: #f7f7f7;
    float: left;
    margin: 0;
    font-size: 18px;
    padding: 0 5em;
}
@media only screen and (min-width:1367px){
    .team-home{
        font-size: 20px;
    }
}
.ourTeam-home{
    width:20%;
    border-bottom: 4px dotted #64d2b5;
    border-style:dashed;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin-bottom: 20px;
}
@media only screen  and (min-width:781px) and (max-width:1366px){
    .team-home{
        padding: 0 3%;
    }
}
@media only screen and (max-width:1220px){
    .team-home{
        width: 70%;
    }
}
@media only screen and (max-width:780px){
    .detail-home{
        border-bottom: 2px solid white;
        border-right:0;
        padding-bottom:5px  ;
    }
    #about{
        height: 150%;
    }
    .team-home{
        width:100%;
        height: 100%;
        padding: 0 7%;
    }
    .ourTeam-home .p{
        font-size: 3em;
        width:100%
    }

}