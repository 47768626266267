.main-footer {
    color: white;
    background-color: #343a40;
    padding-top: 3em;
    width: 100%;
    text-align: left;
    margin-top: auto;
    /* position:absolute;
    left:0;
    bottom:0;
    right:0; */
}
.main-footer h4{
    font-size: 1.5rem;
}
.main-footer a{
    color: white;
}
.main-footer a:hover{
    color: #edcd1f;
}
.footer-icon{
    padding: 0;
    margin: 0;
}