table {
  width: 100%;
}


.Kayanthead {
  font-family: 'Abel', sans-serif;
}

tbody {
  font-family: arial, sans-serif;
  background-color: white;
}

td, th {
  padding: 0.5em;
  font-size: 0.85vw;
  font-weight: 700;
  border: 1px solid #dee2e6;
}

tr {
  background-color: rgba(0,0,0,.05);
}

tr:nth-child(even) {
  background-color: white;
}

.singleTrBody {
  background-color: white;
}