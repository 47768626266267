.tdBody td{
    padding: 0;
    background-color: white;
}
.table1{
    margin: 50px auto;
    width: 75%;
}
.tbodyTable2{
    padding: 5px;
    background-color: white;
}