.allSol h2 {
  font-size: 3rem;
  /* margin-bottom: 10px; */
  color: #1e2d3b;
  /* text-align: left; */
  /* padding-left:8% ; */
}
.bloc-tabs {
  display: flex;
}
.tabs {
  color: #1e2d3b;
  font-size: 1.5rem;
  font-weight: 600;
  background: red;
  padding: 15px;
  text-align: center;
  width: 50%;
  background: white;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  background: white;
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: #edcd1f;
}

button {
  border: none;
}

.movement {
  border: none;
  background-color: #1e2d3b !important;
  color: #edcd1f !important;
}

.movement:hover {
  background-color: #edcd1f !important;
  color: #1e2d3b !important;
}

.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 10px;
  background: #edcd1f;
  margin-bottom: 5px;
}
/* .content p {
    width: 100%;
    height: 100%;
  } */
.active-content {
  display: block;
}
.text {
  text-align: left;
  font-size: 1.07em;
}
.flexinfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}
.left-provider {
  width: 50%;
  padding: 4em 0;
  float: left;
}
.spantext {
  font-size: 1.5em;
  float: left;
  margin-left: 0;
}
.right-provider {
  width: 40%;
  display: block;
  float: right;
}
.features {
  float: left;
  text-align: left;
}
.features ol li {
  text-decoration: none;
  font-size: 0.9em;
}
.img {
  width: 50%;
  height: 50%;
  margin-top: 2.5em;
}
.features ul li {
  font-size: 1.05em;
  text-decoration: none;
  float: left;
}

@media only screen and (max-width: 959px) {
  .right-provider {
    display: none;
  }
  .left-provider {
    width: 100%;
  }
}
.btns {
  padding: 0 0 3rem 0;
}
@media only screen and (max-width: 520px) {
  /* .img{
        width:60%;
        height: 45%;
        margin-top: 2.5em;
    } */

  .leftBtn {
    margin-bottom: 3em;
  }
  .tabs {
    width: 20%;
    font-size: 0.9rem;
  }
}
