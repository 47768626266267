.link-nav:hover {
  color: #edcd1f!important;
}
.link-nav{
  border-radius: 5px;
}

.line-nav{
  padding: 0;
  margin: 0;
}
.hoverMenue-nav{
  margin-top:0.3rem;
  padding: 0;
  width: 10.5rem;
  font-size: .95rem;
  background-color: #565b61;
  position: absolute
}
.dropDownItem-nav{
  width:100%;
  padding: 0.3rem 0.2rem;
}
.dropDownItem-nav:hover{
  background-color:#5d666f;
  font-size: 1.0rem;
}

.linkDropDown-nav{
  color: white;
  padding: .5rem;
  margin: 0;
  width: 100%;
}
.linkDropDown-nav:hover{
  color: white;
}
.dropDownItem-nav{
  padding: .7rem 0;
}
.kareem{
  min-height: 100vh;
}
@media only screen and (max-width:768px){
  .hoverMenue-nav{
    position: static;
    margin:0 auto;
    width: 90%;
  }

}
@media only screen and (max-width:763px){
  .kareem{
    flex-direction: column;
  }

}
/* .searchBox{
  height: 2.8rem;
} */
