.cont{
    padding: 40px 10%;
    font-family: Poppins,sans-serif;
    /* background-color: #f7f7f7; */
    width: 100%;
    text-align: left;
    min-height: 40rem;
}
.content{
    font-size: 1.2rem;
    font-weight: 600;
    line-height:1.3rem;
}
.header{
    font-size: 2.5rem;
    margin-bottom: .9rem;
}
.row{
    display: flex;
    width:65%;
    height:22rem;
    margin-left:17.5%;
    margin-bottom: 5rem;
    box-sizing: border-box;

}
.column{
    padding-left:1rem;
    padding-right:1rem;
    background-color:#f5f5f5;
    width:50%;
    padding-top:1.5rem;
    border-radius:0.3rem;
    margin-right:0;
    border-right:0
}
.column1{
    width: 50%;
}
.map{
    width:100%;
    height: 100%;
    border: 1px solid white;
    padding: 0;
}
@media only screen and (max-width:786px){
    .row{
        flex-direction: column;
        width: 90%;
        height: 30rem;
        margin-left: 5%;
        height: 28rem;

    }
    /* .map{
        height: %;
    } */
    .column{
        width: 100%;
        
    }
    .column1{
        width: 100%;
    }

}