.container{
    width: 100%;
    font-family: Poppins,sans-serif;
    top: 0;
}
.top{
    width: 100%;
    height: 660px;
}
.right{
    text-align:left;
    color:#1e2d3b;
    background-color:#e1e0e0;
    opacity:0.9;
    width:50%;
    float: right;
    height: 660px;
    padding: 40px 10px;
}
.bottom{
    text-align:center;
    height: 650px;
}
.textImage{
    padding: 10% 10%;
}
.header2{
    color:#1e2d3b;
    font-size:50px;
    text-transform:uppercase;
    line-height:1.3em;
}
.text2{
    font-size:20px;
    color:black;
    text-transform:uppercase;
    font-weight:bold;
    margin-left: 10%;
}
.header3{
    font-size:33px;
    text-transform:uppercase;
    line-height:1.3em;
    font-weight:bold;
    margin-left: 10%;
}
.info{
    font-size:16px;
    margin-left: 10%;
}
.text3{
    font-size:16px;
    margin-left: 10%;
}
@media only screen and (max-width:705px) {
    .right{
        width:100%;
        height: 680px;
    }
    
}
@media only screen and (max-width:420px) {
    .header2{
        font-size:35px;
    }
    .text2{
        font-size:16px;
    }
    
}