.CheckBox {
    width: 40em;
    margin-left:-360px;
    }
    .CheckBox1 {
        width: 40em;
        float: right;
        margin-top: -400px;
        }
        .CheckBox2 {
            width: 40em;
            float: right;
            margin-top: -350px;
            }
            .CheckBox3 {
                width: 40em;
                float: right;
                margin-top: -225px;
                }
                .CheckBox4 {
                    width: 40em;
                    float: right;
                    margin-top: -265px;
                    }
                    .CheckBox5 {
                        width: 40em;
                        float: right;
                        margin-top: -90px;
                        margin-right: 450px;
                        }
                        .CheckBox6 {
                            width: 40em;
                            float: right;
                            margin-top: -204px;
                            margin-right: 0px;
                            }
                            .CheckBox7 {
                                width: 40em;
                                float: right;
                                margin-top: -165px;
                                margin-right: 0px;
                                }
                                .CheckBox8 {
                                    width: 40em;
                                    float: right;
                                    margin-top: -125px;
                                    margin-right: 0px;
                                    }