/*.Radio{
   
        margin: 20px auto !important;
}
*/

input[type="radio"] {
        -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
        -moz-appearance: checkbox;    /* Firefox */
        -ms-appearance: checkbox;     /* not currently supported */
      }


     span{
        margin-left: 10px;
     }


     

      /*
 style={{
    width: "50%",
    margin: "10px auto",
    backgroundColor: "rgb(58, 180, 73)",
    color: "white",
    backgroundColor: "#3ab449",padding: "5px"}}

      */