
.container{
    height: 760px;
    text-align: left;
}

.header{
    width:100%;
    font-size: 45px;
    color: #509c35;
    margin-top: 1em;
    margin-bottom: .5em;
}

.text{
    color: #509c35;
    border-bottom: 1px solid #509c35;
}
.company{
    background-color: #edcd1f;
    width: 100%;
    color:#3d5975;
    padding: 35px;
    text-align: center;
}
.companyHeader{
    font-size: 55px;
    font-weight: bold;
}
.companyText{
    font-size: 20px;
    font-weight: bold;
}
.detail{
    border-right: 2 px solid white;
}
@media only screen and (min-width:1367px) and (max-width:1700px){
    .container{
        height: 950px;
    }

}


/* @media only screen and (max-width:600px){
    .detail{
        border-right: 0;
    }
    
} */
