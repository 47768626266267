.suggestions {
    
    list-style: none;
    
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    text-align: left;
    margin: 10px auto;
    width: 70%;
    
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #3ab449;
    color: white;
    cursor: pointer;
    
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #c4c4c4;
  }