select {
  border: 1px solid #ccc;
  width: 70% !important;
  margin: 20px auto !important;
  display: block !important;
  padding: 10px;
  background-color: white;
  color: #000;
  cursor: pointer;
  outline: none;
}

.UpdateSelect, .CodesSelect {
  width: 100% !important;
  border: 1px solid #ccc;
  margin: 2vh 0;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}

.UpdateSelect, .CodesSelect1 {
  width: 36% !important;
  border: 1px solid #767676;
  margin: 2vh 0;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect2 {
  float: left;
  width: 10% !important;
  border: 1px solid #767676;
  margin: 2vh 0;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect3 {
  width: 100% !important;
  border: 1px solid #767676;
  margin: 2vh 0;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect4 {
  width: 20% !important;
  border: 1px solid #767676;
  margin: 4vh ;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect5 {
  width: 20% !important;
  border: 1px solid #767676;
  margin: 4vh ;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect6 {
  width: 20% !important;
  border: 1px solid #767676;
  margin: 4vh ;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}
.UpdateSelect, .CodesSelect7 {
  width: 145% !important;
  border: 1px solid #767676;
  margin: 4vh ;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}

.UpdateSelect, .CodesSelect8 {
  width: 200% !important;
  border: 1px solid #767676;
  margin: 4vh ;
  padding: 1vh 0.6vw;
  background-color: white;
  color: #000;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  outline: none;
}


.fullWidthSelect {
  width: 100% !important;
  margin: 15px auto !important;
  padding: 10px;
}