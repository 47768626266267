.cont{
    padding: 40px 0;
    font-family: Poppins,sans-serif;
}
.header{
    font-size: 50px;
}
/* .cards{
    margin: 0 auto;
    
} */

.borderBottom{
    border-top: 10px solid #509c35;
    width: 120px;
    margin: 0 auto;
    padding-bottom:40px ;
    color:#1e2d3b
}

.cardHeader{
    font-size: 18px;
    color: #509c35;
}
/* .serviceImg{
    width:10em;
    height: 27vh;
} */
/* .column{
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
    position: relative;
    width: 20%;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
} */
.service{
    box-shadow:1px #1e2d3b;
    min-height: 24em;
}
/* @media only screen and (min-width:1367px){
    .service{
        min-height: 30em;
        width: 100%;
    }
} */
@media only screen  and (max-width:815px){

    .service{
        min-width:24rem
    }

}