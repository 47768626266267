form {
  width: 50%;
  margin: 30px auto;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
}
.ruleScreen {
  width: 50%;
  margin: 22px auto;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
}

.kareemBenefitsFilters {
  width: 90%;
  margin: 22px auto;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
}
/* .form {
    width: 111%;
    margin-left: -16px;
    border: 0;
 
} */
.template {
  width: 60%;
  margin: 30px auto;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
}
.popup form {
  width: 100%;
  margin: -20px auto;
  box-shadow: none;
}

.popup1 form {
  width: 123%;
  margin: -20px auto;
  box-shadow: none;
}
.Form1 {
  border: 2px solid #f4511e;
}

.Form2 {
  border: 2px solid #1ef45e;
  background-color: #f2f2f29a;
  width: 100%;
}

.Form3 {
  border: 2px solid #000;
}
.form1 {
  position: relative;
  width: 90%;
  display: flex;
  border: 0;
  flex-flow: column wrap;
}
.form2 {
  position: relative;
  width: 70%;
  display: flex;
  border: 0;
  flex-flow: column wrap;
}
.form-sama {
  width: 100%;
  text-align: center;
  line-height: 30px;
}
.form-sama1 {
  width: 100%;
  text-align: center;
  line-height: 30px;
}
.flex-item1 {
  position: absolute;
  top: 0px;
  left: 0px;
}
.flex-item2 {
  margin: 5px;
  padding: 4px;
}
.flex-item3 {
  position: absolute;
  top: 0px;
  right: -20px;
}
.flex-item4 {
  position: absolute;
  top: 0px;
  right: 0px;
}
.flex-item5 {
  position: absolute;
  top: -10px;
  right: 0px;
}
.flex-item6 {
  position: absolute;
  top: -20px;
  right: 0px;
}

.flex-item7 {
  position: absolute;
  top: -40px;
  right: 244px;
}
.flex-item8 {
  position: absolute;
  top: -40px;
  right: 244px;
}
.flex-item9 {
  position: absolute;
  top: 40px;
  right: 430px;
}
.flex-item10 {
  position: absolute;
  top: 40px;
  right: 185px;
}
.flex-item11 {
  position: absolute;
  top: 38px;
  left: -40px;
}
.flex-item12 {
  position: absolute;
  top: 10px;
  left: 0px;
}
.flex-item13 {
  position: absolute;
  top: 35px;
  left: 188px;
}
.form-sama > div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.form-sama1 > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-sama1 {
  position: absolute;
  top: -2px;
  right: 10px;
}
.flex-sama2 {
  position: absolute;
  top: -2px;
  right: -190px;
}
.flex-sama3 {
  position: absolute;
  top: 45px;
  right: 0px;
}
.flex-sama4 {
  position: absolute;
  top: 45px;
  right: -210px;
}
.flex-sama5 {
  position: absolute;
  top: 100px;
  right: 0px;
}
.flex-sama6 {
  position: absolute;
  top: 100px;
  right: -210px;
}
.flex-sama7 {
  position: absolute;
  top: 0px;
  right: 200px;
}
.flex-sama8 {
  position: absolute;
  top: 0;
  right: -40px;
}

.Search {
  border: 2px solid #f2f2f29a;
  box-shadow: 0 12px 5px rgba(0, 0, 0, 0.24);
}

.popupForm {
  border: 0.5px solid #1ef45e;
  background-color: white;
  padding: 10px;
}

.form4 {
  margin-left: 0em;
  width: 65%;
  padding: 20px;
  margin: 5;
  outline: none;
}
.form5 {
  margin-left: 0em;
  padding: 20px;
  margin: 5;
  outline: none;
}
.some-page-wrapper {
  margin: 30px;
  background-color: red;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

@media only screen and (max-width: 1200px) {
  form,
  .template {
    width: 90%;
  }
}
