/* barra geral */
/* .MuiDrawer-paper {
  background-color: white !important;
  color: var(--back-color-ebebeb) !important;
  box-shadow: 5px 5px 10px 0px rgba(50, 50, 50, 0.75) !important;
  border: none !important;
} */

.MuiDrawer-docked .MuiPaper-elevation {
  background-color: #1976d2 !important;
}

.css-rqglhn-MuiTable-root caption {
  caption-side: top !important;
}
