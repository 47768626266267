a{
    text-decoration: none;
    cursor: pointer;
    
}
.Link1{
    color:#f4511e;
    font-size: 15px;
}

.Link2{
   
    color: #3ab449 !important;
    
}

.Link3{
    font-family:Georgia;
    font-size: 25px;
    color: black;
}
.BS10 {
    padding: 10px 35px;
    width: 30%;
    margin: auto 10px;
    margin-bottom: 15px;
    background-color: #3ab449;
    border: none;
    color: white !important;
    border-radius: unset !important;
}
.dangerBS1 {
        display:inline-block;
        background-color: #dc3545;
        border: none;
        color: white !important;
        width: 23%;
        margin: 20px 10px;
        padding: 10px;
   
}