/*.Header{
    display: flex;
    flex-grow: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}*/

.HomeTitle{
    
    color: white;
    text-align: center;
    padding: 1%;
    margin: 0%;
  }



  .logout{
    background-color:#28a745;
    color: white;
    border: none;
    font-size: medium;
    padding: 0.5vh 1vw;
    margin: 1%;
    border-radius: unset;

  }

  .dropdown button{
    border-radius: 50%;
    margin-top: 5px;
  }
